<template>
  <div class="container">
    <ShowPath :path="this.$route.fullPath" />
    <!--<Loading :spinner="this.spinner" v-if="this.spinner" />-->
    <div class="repos">
      <ul v-for="(elem, i) in repos" :key="i">
        <li class="repo" @click="goto(elem)">
          <h2 class="elem-list--name">{{ elem.name }}</h2>
          <p class="elem-list--modTime">{{ elem.modTime }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "@/components/Loading.vue";
import ShowPath from "@/components/ShowPath.vue";
export default {
  name: "Repos",
  components: {
    //Loading,
    ShowPath,
  },
  data() {
    return {
      spinner: { type: Boolean, default: true },
      repos: { type: Array, default: [] },
      path: { type: String, default: "" },
      api: {
        dev: process.env.VUE_APP_API_DEV,
        prod: process.env.VUE_APP_API_PROD,
      },
    };
  },
  async created() {
    this.path = this.$route.fullPath;
    await this.getRepoList();
  },
  methods: {
    async getRepoList() {
      this.spinner = true;
      this.repos = [];
      await axios
        .get("http://localhost:8002")
        .then((response) => {
          response.data.forEach((d) => {
            if (Object.prototype.hasOwnProperty.call(d, "err")) throw d;
            const r = {
              name: d.name,
              modTime: this.formatTimestamp(d.modTime),
            };
            this.repos.push(r);
          });
          this.repos.sort();

          this.spinner = false;
        })
        .catch((err) => {
          this.spinner = false;
          this.$router.push({
            name: "Error",
            params: {
              0: "",
              code: err.code,
              msg: err.msg,
              path: this.path,
            },
          });
        });
    },
    goto(elem) {
      if (!this.$router.getRoutes().some(({ name }) => name === elem.name)) {
        this.$router.addRoute({
          p_name: elem.name,
          name: elem.name,
          path: `/${elem.name}`,
          component: () => import("@/views/Repo.vue"),
        });
      }
      this.$router.push({
        name: `${elem.name}`,
      });
    },
    formatTimestamp(mtime) {
      const date = new Date(mtime * 1000);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      return [
        months[date.getMonth()],
        " ",
        date.getDate(),
        ", ",
        date.getFullYear(),
        " ",
        date.getHours() % 12 || 12,
        ":",
        (date.getMinutes() < 10 ? "0" : "") + date.getMinutes(),
        " ",
        date.getHours() >= 12 ? "pm" : "am",
      ].join("");
    },
    formatFileSize(bytes) {
      let format = ["bytes", "KB", "MB", "GB"];
      for (var pos = 0; bytes >= 1000; pos++, bytes /= 1024);
      let d = Math.round(bytes * 10);
      return pos
        ? [parseInt(d / 10), ".", d % 10, " ", format[pos]].join("")
        : bytes + " bytes";
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}
.title {
  text-align: left;
  padding-left: 1em;
  color: var(--main-front-color);
  text-decoration: underline;
}
.repos {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: left;
  overflow: hidden;
}
.repo {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-basis: 1px;
  flex-grow: 0;
  flex-shrink: 0;
  border: 6px outset var(--main-front-color);
  border-radius: 4px;
  cursor: pointer;
  max-width: 33%;
}
.repo a {
  color: var(--main-front-color);
  text-decoration: none;
}

.repo:hover {
  border: 6px inset var(--main-front-color);
  transform: scale(1.05);
}
.repo .elem-list--name:hover {
  color: white;
  text-decoration: underline overline;
  text-decoration-color: var(--main-front-color);
}

.elem-list--name {
  text-align: left;
}
.elem-list--modTime {
  text-decoration: underline dotted;
}
</style>
