<template>
  <div>
    <Repos />
  </div>
</template>

<script>
import Repos from "@/views/Repos.vue";
export default {
  name: "Home",
  components: {
    Repos,
  },
};
</script>
