<template>
  <div class="wrapper-loading" v-if="spinner">
    <dialog open class="loading-text">
      <div class="spinner"></div>
      <h1 class="loading-text">loading...</h1>
    </dialog>
  </div>
</template>
<script>
export default {
  name: "LoadingComponent",
  props: {
    spinner: { type: Boolean, required: true },
  },
};
</script>
<style scoped>
dialog {
  background-color: white;
}
.loading-text {
  background-color: var(--main-back-color);
  margin: auto;
  color: grey;
  width: 22ch;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.2em;
  z-index: 1;
}

.spinner {
  margin: auto;
  border: 12px solid var(--main-back-color);
  border-radius: 75%;
  border-top: 12px solid var(--main-front-color);
  border-bottom: 12px solid var(--main-front-color);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
